import './App.css';
import RSLogo from './Logo.svg';
import React, { useEffect, useState, useRef } from 'react';
import person1Image from './JoaoMeneses.png';
import person2Image from './JoaoSilva.png'
import person3Image from './MiguelIsidoro.png'
import { FaArrowDown, FaArrowUp, FaLinkedin, FaPlay, FaPause, FaForward } from 'react-icons/fa';
import sound2 from './sound1.wav'
import sound1 from './sound2.wav'
import sound3 from './sound3.wav'
import sound4 from './sound4.wav'
function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const sectionIds = ['slogan', 'what-we-do', 'how-it-works', 'who-we-are', 'contact-us'];
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef1 = useRef(new Audio(sound1));
  const audioRef2 = useRef(new Audio(sound2));
  const audioRef3 = useRef(new Audio(sound3));
  const [currentSongIndex, setCurrentSongIndex] = useState(0);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const audioRefs = [useRef(new Audio(sound1)), useRef(new Audio(sound2)), useRef(new Audio(sound3)), useRef(new Audio(sound4))];

  useEffect(() => {
    const header = document.querySelector('.App-header');
    setHeaderHeight(header.offsetHeight);
    if (isPlaying) {
      audioRefs[currentSongIndex].current.play();
    } else {
      audioRefs.forEach(audioRef => {
        audioRef.current.pause();
      });
    }

    const handleScroll = () => {
      const scrollPosition = window.scrollY + header.offsetHeight;

      const currentSection = sectionIds.findIndex(sectionId => {
        const sectionEl = document.getElementById(sectionId);
        if (!sectionEl) return false;

        const sectionTop = sectionEl.offsetTop;
        const sectionHeight = sectionEl.clientHeight;

        return scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight;
      });

      if (currentSection !== -1) {
        setCurrentSectionIndex(currentSection);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [headerHeight, sectionIds, isPlaying]);


  useEffect(() => {
    return () => {
      audioRef1.current.pause();
      audioRef2.current.pause();
      audioRef3.current.pause();
    };
  }, []);

  const scrollToSection = (index) => {
    const sectionId = sectionIds[index];
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    setCurrentSectionIndex(index);
  };


  const handleHeaderLinkClick = (sectionId) => {
    const index = sectionIds.indexOf(sectionId);
    if (index !== -1) {
      scrollToSection(index);
    }
  };

  const handleContactSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const email = formData.get('email');
    const message = formData.get('message');

    console.log('Email:', email);
    console.log('Message:', message);

    event.target.reset();
  };

  const playNextSong = () => {
    const nextSongIndex = (currentSongIndex + 1) % audioRefs.length;
    audioRefs[currentSongIndex].current.pause();
    audioRefs[currentSongIndex].current.currentTime = 0;
    setCurrentSongIndex(nextSongIndex);
    setIsPlaying(true);
  };


  return (
    <div className="App">
      <header className="App-header">
        <a href="#what-we-do" className="App-nav-link" onClick={() => handleHeaderLinkClick('what-we-do')}>What We Do</a>
        <a href="#how-it-works" className="App-nav-link" onClick={() => handleHeaderLinkClick('how-it-works')}>How it works</a>
        <a href="#slogan" className="App-header-content" onClick={() => handleHeaderLinkClick('slogan')}>
          <img src={RSLogo} className="App-RSLogo" alt="RisingSound Logo" />
        </a>
        <a href="#who-we-are" className="App-nav-link" onClick={() => handleHeaderLinkClick('who-we-are')}>Who We Are</a>
        <a href="#contact-us" className="App-nav-link" onClick={() => handleHeaderLinkClick('contact-us')}>Contact Us</a>
      </header>

      <main>
        <section id="slogan" className="App-section">
          <div className='buttons-container'>
            <button className={`play-button ${isPlaying ? 'is-playing' : ''}`} onClick={togglePlay}>
              {isPlaying ? <FaPause /> : <FaPlay />}
              <div className={isPlaying ? 'wave wave-animation' : 'wave'}></div>
            </button>
            <button className="skip-button" onClick={playNextSong}>
              <FaForward />
            </button>
          </div>
          <h2 className='App-slogan'>Music was never so rewarding</h2>
          <p className='section-description'>
            RisingSound emerges as a new approach to music promotion. Artists increase their reach and visibility, while being listened to and getting feedback on their music. Listeners can discover new songs and artists, while having the opportunity to be rewarded.
          </p>
        </section>
        <section id="what-we-do" className="App-section">
          <h2 className='section-title'>What We Do</h2>
          <p className='section-description'>
            We are creating a platform where every single day, a new playlist is released with 50 songs from 50 different artists. These songs are shuffled in a unique order for each user in order to guarantee that the experience is fair for both artists and listeners. The songs are also separated into different playlists based on each type of music, perfect for exploring your favourite style of music and for shorter listening sessions.
          </p>
        </section>
        <section id="how-it-works" className="App-section">
          <h2 className='section-title'>How it works</h2>
          <p className='section-description'>
            Artists can personalize their profiles, while adding important links and concert dates to the calendar so that the listeners can know more about them. They can also upload songs and purchase credits which can be used to publish their music on the playlist. Listeners play and rate the songs and have the opportunity to learn more about the artists. All os these activities earn them tickets that increase their chance of winning cash prizes. Winners can choose to keep their prize or donate a share of it to their favourite artist of the day, supporting the music they love.
          </p>
        </section>
        <section id="who-we-are" className="App-section">
          <h2 className='section-title'>Who We Are</h2>
          <div className="team-cards">
            <div className="team-card">
              <img src={person3Image} alt="Miguel Isidoro" className="team-image" />
              <div className="card-info">
                <p className="name">
                  Miguel Isidoro
                  <a href="https://www.linkedin.com/in/miguelnunesisidoro/" target="_blank" rel="noopener noreferrer" className="linkedin-icon">
                    <FaLinkedin />
                  </a>
                </p>
              </div>
            </div>
            <div className="team-card">
              <img src={person1Image} alt="Joao Meneses" className="team-image" />
              <div className="card-info">
                <p className="name">João Meneses
                  <a href="https://www.linkedin.com/in/joaommeneses/" target="_blank" rel="noopener noreferrer" className="linkedin-icon">
                    <FaLinkedin />
                  </a>
                </p>
              </div>
            </div><div className="team-card">
              <img src={person2Image} alt="Joao Silva" className="team-image" />
              <div className="card-info">
                <p className="name">João Silva<a href="https://www.linkedin.com/in/joaod-silva/" target="_blank" rel="noopener noreferrer" className="linkedin-icon">
                  <FaLinkedin />
                </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="contact-us" className="App-section">
          <h2 className='section-title'>Contact RisingSound</h2>
          <p className='contact-description'>We're here to help and answer any question you might have. We look forward to hearing from you.</p>
          <form className="contact-form" onSubmit={handleContactSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="first-name" className='section-title' style={{ fontSize: '18px' }}>First Name</label>
                <input type="text" id="first-name" name="firstName" required />
              </div>
              <div className="form-group">
                <label htmlFor="last-name" className='section-title' style={{ fontSize: '18px' }}>Last Name</label>
                <input type="text" id="last-name" name="lastName" required />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="contact-email" className='section-title' style={{ fontSize: '18px' }}>Email</label>
              <input type="email" id="contact-email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="contact-message" className='section-title' style={{ fontSize: '18px' }}>Message</label>
              <textarea id="contact-message" name="message" required></textarea>
            </div>
            <button type="submit" className="contact-submit" style={{ fontFamily: 'Merriweather' }}>Send Message</button>
          </form>
          <footer className="App-footer">
            © 2023 RisingSound
          </footer>
        </section>
      </main>

      {
        currentSectionIndex > 0 && (
          <button
            className="scroll-arrow up"
            onClick={() => scrollToSection(currentSectionIndex - 1)}
          >
            <FaArrowUp />
          </button>
        )
      }
      {
        currentSectionIndex < sectionIds.length - 1 && (
          <button
            className="scroll-arrow down"
            onClick={() => scrollToSection(currentSectionIndex + 1)}
          >
            <FaArrowDown />
          </button>
        )
      }

    </div >
  );
}

window.addEventListener('scroll', () => {
  const sections = document.querySelectorAll('.App-section');
  const navLinks = document.querySelectorAll('.App-nav-link');

  let current = '';

  sections.forEach((section) => {
    const sectionTop = section.offsetTop;
    const sectionHeight = section.clientHeight;
    if (window.scrollY >= (sectionTop - sectionHeight / 3)) {
      current = section.getAttribute('id');
    }
  });

  navLinks.forEach((link) => {
    link.classList.remove('active');
    if (link.href.includes(current)) {
      link.classList.add('active');
    }
  });
});
export default App;
